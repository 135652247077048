import { Fragment, useState } from 'react';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Button,
  Box,
} from '@mui/material';
import { css, Stack } from '@mui/system';
import { IconLoader, ScorencoLogo } from '@scorenco/components';
import { RouteLink, useTranslation } from '@scorenco/core';
import { usePageContext } from '@scorenco/next';

import { WebLayoutPageProps } from '..';
import { RouteKey } from '../../../routes';
import { T_KEYS } from '../../../translations';

export const MobileHeaderMenu = ({ ...rest }) => {
  const { t } = useTranslation();
  const [currentMenu, setCurrentMenu] = useState<string | undefined>();
  const pageContext = usePageContext<WebLayoutPageProps>();

  const menu = pageContext.pageProps.menu;
  if (!menu?.items?.length) {
    return null;
  }

  return (
    <Stack direction="column" gap={0} {...rest}>
      <RouteLink href={{ routeKey: RouteKey.Home }}>
        <Box component="a">
          <ScorencoLogo m={2} width="8rem" />
        </Box>
      </RouteLink>
      <List component="nav" disablePadding>
        {menu.items.map((menu) => (
          <Fragment key={menu.id}>
            <ListItemButton
              onClick={() => {
                setCurrentMenu(currentMenu === menu.id ? undefined : menu.id);
              }}
            >
              <ListItemText primary={menu.attributes?.title} />
              {currentMenu === menu.id ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={currentMenu === menu.id} timeout="auto">
              <List
                component="div"
                dense={true}
                disablePadding
                sx={{ mb: 1 }}
                css={css`
                  & .MuiListItemIcon-root {
                    min-width: 1.5rem;
                    margin-right: 1rem;
                  }
                `}
              >
                {menu.items?.map((subMenu) => (
                  <RouteLink
                    key={subMenu.id}
                    passHref
                    href={subMenu.attributes?.url}
                  >
                    <ListItemButton
                      component="a"
                      sx={{ minHeight: '3rem' }}
                      target={
                        subMenu.attributes?.target
                          ? '_' + subMenu.attributes?.target
                          : undefined
                      }
                    >
                      <ListItemIcon>
                        {subMenu.attributes?.main_icon && (
                          <IconLoader
                            css={css`
                              width: 1.5rem;
                              height: 1.5rem;
                            `}
                            icon={subMenu.attributes?.main_icon as any}
                          />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={subMenu.attributes?.title}
                        secondary={subMenu.attributes?.main_text}
                        css={(theme) => css`
                          .MuiListItemText-secondary {
                            color: ${theme.vars.palette.text.primary};
                            opacity: 0.7;
                            font-size: 0.75rem;
                            line-height: 1.2;
                          }
                        `}
                      />
                    </ListItemButton>
                  </RouteLink>
                ))}
              </List>
            </Collapse>
          </Fragment>
        ))}
      </List>
      <Stack gap={2} alignItems="stretch" sx={{ m: 2 }}>
        <RouteLink
          href={[
            `${process.env.NEXT_PUBLIC_MOBILE_URL}/auth/select-sport`,
            typeof window !== 'undefined' &&
              `redirectTo=${encodeURIComponent(window.location.href)}`,
          ]
            .filter(Boolean)
            .join('?')}
        >
          <Button
            component="a"
            sx={{ width: '100%' }}
            color="secondary"
            variant="contained"
          >
            {t(T_KEYS.AUTHENTIFICATION_SIGN_UP)}
          </Button>
        </RouteLink>
        <RouteLink
          href={[
            `${process.env.NEXT_PUBLIC_MOBILE_URL}/auth/sign-in`,
            typeof window !== 'undefined' &&
              `redirectTo=${encodeURIComponent(window.location.href)}`,
          ]
            .filter(Boolean)
            .join('?')}
        >
          <Button
            component="a"
            sx={{ width: '100%' }}
            color="primary"
            variant="outlined"
          >
            {t(T_KEYS.AUTHENTIFICATION_SIGN_IN)}
          </Button>
        </RouteLink>
      </Stack>
    </Stack>
  );
};

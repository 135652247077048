import { useMemo } from 'react';

import { Button } from '@mui/material';
import { Stack } from '@mui/system';
import { RouteLink, useTranslation } from '@scorenco/core';

import { T_KEYS } from '../../../translations';

export const AuthMenu = () => {
  const { t } = useTranslation();

  const authenticated = useMemo(() => {
    if (typeof window !== 'undefined') {
      const cookies = document.cookie;
      return cookies.includes('snc-status=connected');
    }
    return false;
  }, []);

  if (authenticated) {
    return (
      <RouteLink href={process.env.NEXT_PUBLIC_MOBILE_URL}>
        <Button component="a" color="secondary" variant="contained">
          Tableau de bord
        </Button>
      </RouteLink>
    );
  }

  return (
    <Stack gap={2} direction="row" justifyContent="center" alignItems="center">
      <RouteLink
        href={[
          `${process.env.NEXT_PUBLIC_MOBILE_URL}/auth/select-sport`,
          typeof window !== 'undefined' &&
            `redirectTo=${encodeURIComponent(window.location.href)}`,
        ]
          .filter(Boolean)
          .join('?')}
      >
        <Button component="a" color="secondary" variant="contained">
          {t(T_KEYS.AUTHENTIFICATION_SIGN_UP)}
        </Button>
      </RouteLink>
      <RouteLink
        href={[
          `${process.env.NEXT_PUBLIC_MOBILE_URL}/auth/sign-in`,
          typeof window !== 'undefined' &&
            `redirectTo=${encodeURIComponent(window.location.href)}`,
        ]
          .filter(Boolean)
          .join('?')}
      >
        <Button component="a" color="secondary" variant="outlined">
          {t(T_KEYS.AUTHENTIFICATION_SIGN_IN)}
        </Button>
      </RouteLink>
    </Stack>
  );
};
